﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from '../../providers/generic.service';
import { environment } from '../../../environments/environment';
import { GlobalService } from 'app/providers/global.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class PageLoginComponent
{
    model: any = {};
    loading = false;
    error = '';

    constructor(private router: Router)
    {
    }

    login()
    {         
        this.router.navigate(['/facing']);
    }
}
