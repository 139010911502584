﻿import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { map, catchError, debounceTime } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { GenericService } from './generic.service';
import { GlobalService } from './global.service';


@Injectable()
export class ChartService
{
    constructor(private http: HttpClient)
    {

    }

    async fetchData()
    {
    }

    getChartData(fileName)
    {
        fileName = "assets/data/" + fileName + ".json";
        return this.http.get(fileName).pipe(
            map((res: HttpResponse<any>) =>
            {
                //res = res.json();
                var table = this.dataTableToTable(res);
                return table;
            }));
    }

    transposeTable(table)
    {
        let newStubs = [];
        let newBanners = [];

        table.Stubs.forEach(stub =>
        {
            newBanners.push({ Id: stub.Id, Name: stub.Name, Color: stub.Color, Base: stub.Base, Values: [] });
        });

        table.Banners.forEach((banner) =>
        {
            let newStub = { Id: banner.Id, Name: banner.Name, Color: banner.Color, Base: banner.Base };
            newStubs.push(newStub);

            for (let j = 0; j < newBanners.length; j++)
            {
                newBanners[j].Values.push(banner.Values[j]);
            }
        });

        table.Stubs = newStubs;
        table.Banners = newBanners;
        return table;
    }

    tableToDataTable(table, freq = false)
    {
        let dt = [];

        table.Stubs.forEach((stub, i) =>
        {
            let row = { '': stub.Name };
            table.Banners.forEach(banner =>
            {
                row[banner.Name] = freq && banner.Values[i].Freq != undefined ? banner.Values[i].Freq : banner.Values[i].Perc;
                row[banner.Name] = row[banner.Name] + (freq && banner.Values[i].Freq != undefined ? "" : "%");
            });
            dt.push(row);
        });

        return dt;
    }

    dataTableToTable(dt)
    {
        let table = { Banners: [], Stubs: [] };

        if (dt.length == 0)
            return table;

        let keys = []; let firstKey;
        for (let row of dt)
        {
            let j = 0;
            for (var key in row)
            {
                if (j == 0)
                {
                    firstKey = key;
                    j++;
                    continue;
                }

                keys.push(key);
            }
        }

        keys = Array.from(new Set(keys));
        keys.forEach(x =>
        {
            table.Banners.push({ Name: x, Values: [] });
        });

        dt.forEach((row, i) =>
        {
            table.Stubs.push({ Name: row[firstKey] });
        });

        table.Banners.forEach((banner, i) =>
        {
            table.Stubs.forEach((stub, j) =>
            {
                let value = 0;
                if (dt[j][banner.Name])
                    value = parseFloat(dt[j][banner.Name].toString().replace(",", "")) || 0;
                banner.Values[j] = { Perc: value };
            });
        });

        return table;
    }

    sortBanners(table, bannerOrders)
    {
        table.Banners.sort(function (a, b)
        {
            let x = isNaN(a.Name) ? a.Name : Number(a.Name);
            let y = isNaN(b.Name) ? b.Name : Number(b.Name);

            if (bannerOrders)
                return bannerOrders[x] > bannerOrders[y]
            else
                return x > y;
        });
    }

    sortStubs(table, stubOrders)
    {
        table.Banners.forEach(banner =>
        {
            banner.StubValueMap = banner.StubValueMap || {};
            table.Stubs.forEach((stub, j) =>
            {
                banner.StubValueMap[stub.Name] = banner.Values[j];
            });
        });

        table.Stubs.sort(function (a, b)
        {
            let x = isNaN(a.Name) ? a.Name : Number(a.Name);
            let y = isNaN(b.Name) ? b.Name : Number(b.Name);
            if (stubOrders)
                return stubOrders[x] > stubOrders[y]
            else
                return x > y;
        });

        table.Banners.forEach(banner =>
        {
            banner.Values = [];
            table.Stubs.forEach((stub, j) =>
            {
                banner.Values.push(banner.StubValueMap[stub.Name]);
            });
        });
    }

    exportToCsv(data)
    {
        var options =
            {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalseparator: '.',
                showLabels: true,
                showTitle: false,
                useBom: true,
                headers: Object.keys(data[0])
            };
        //new Angular2Csv(data, 'csv', options);
    }

    static getColor(index)
    {
        if (index == undefined || index == null || index < 0 || this.colors.length <= index)
            return GenericService.getRandomColorHex();
        else
            return ChartService.colors[index];
    }

    static colors = [
        '#005587',
        '#007681',
        '#E87722',
        '#8064A2',
        '#C0504D',
        '#F79646',
        '#71B2C9',
        '#B7BF12',
        '#888B8D',
        '#F1BE48',
        '#3B3EAC',
        '#0099C6',
        '#DD4477',
        '#66AA00',
        '#316395',
        '#994499',
        '#22AA99',
        '#6633CC',
        '#E67300',
        '#252d47',
        '#8B0707',
        '#329262',
        '#5574A6',
        '#3B3EAC',
        "#3e95cd",
        "#8e5ea2",
        "#3cba9f",
        "#e8c3b9",
        "#c45850",
    ]
}
