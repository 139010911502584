import { Injectable } from '@angular/core';

@Injectable()
export class GenericService
{

  static getFileExtension(fileName) {
    return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)
  }

    public static serializeDate(date): string
    {
        return 's';
    }
    public static nullToEmpty(value): string
    {
        value = value ? value : '';
        return value;
    }

    public static isEmpty(value: any): boolean
    {
        // we don't check for string here so it also works with arrays
        return value == null || value.length === 0;
    }

    public static fetchErrorMsg(error)
    {
        if (error == null)
            return "";

        if (typeof error === 'object')
        {
            try
            {
                error = error.json();
            }
            catch (err)
            {
            }

            if (error.message)
                return error.message;

            if (error instanceof ProgressEvent)
            {
                return "Could not connect to server.";
            }

            error = error.error_description || error.Message || error._body || error;

            return JSON.stringify(error);
        }
        return error;
    }

    public static datePickerOptions =
    {
        singleDatePicker: true,
        showDropdowns: true,
        opens: "left",
    }

    public static getPropValue(obj, propPath)
    {
        var arr = propPath.split('.');
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    }

    public static setPropValue(object, path, value)
    {
        var a = path.split('.');
        var o = object;
        for (var i = 0; i < a.length - 1; i++)
        {
            var n = a[i];
            if (n in o)
            {
                o = o[n];
            } else
            {
                o[n] = {};
                o = o[n];
            }
        }
        o[a[a.length - 1]] = value;
    }

    public static toCsv(list, propName = null, seperator = ",")
    {
        if (!list)
            return "";
        var str = "";
        for (let item of list)
        {
            str += seperator + " " + (propName ? item[propName] : item);
        }
        if (str.length > 0)
            return str.substring(2, str.length);

        return str;
    }

    public static getRandomNumber(min, max) //less than max, greater than or equal to min
    {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    public static getRandomColorHex()
    {
        return '#' + Math.random().toString(16).slice(-6);
    }

    public static clone(obj)
    {
        return JSON.parse(JSON.stringify(obj));
    }

    public static getCurrentPath()
    {
        return location.href.split("#")[1];
    }

    public static addToFilterString(url, key, value)
    {
        if (value)
        {
            let qs = "";
            if (url.indexOf('?') == -1)
                qs += "?";
            else
                qs += "&";
            if (url.indexOf('filter=') == -1)
                qs += "filter=";
            else
                qs += ";";
            qs += key + ":" + value;
            return url + qs;
        }
        return url;
    }

    public static addToQueryString(url, key, value)
    {
        if (value)
        {
            let qs = "";
            if (url.indexOf('?') == -1)
                qs += "?";
            else
                qs += "&";
            qs += key + "=" + value;
            return url + qs;
        }
        return url;
    }

    public static csvToJson(csv)
    {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++)
        {

            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++)
            {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        return result;
    }

    public static round(value, upTo = 2)
    {
        let mul = 1;
        for (let i = 0; i < upTo; i++)
            mul *= 10;
        return (Math.round(value * mul) / mul);
    }

    static escapeRegExp(string)
    {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static replaceAll(text, searchText, replaceWith)
    {
        replaceWith = replaceWith || '';
        if (text && searchText)
            return text.replace(new RegExp(GenericService.escapeRegExp(searchText), "g"), replaceWith);

        else
            return text;
    }

    static padLeft(str, targetLength, padWith)
    {
        targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
        padWith = String(typeof padWith !== 'undefined' ? padWith : ' ');
        if (str.length >= targetLength)
        {
            return String(str);
        } else
        {
            targetLength = targetLength - str.length;
            if (targetLength > padWith.length)
            {
                padWith += padWith.repeat(targetLength / padWith.length); //append to original to ensure we are longer than needed
            }
            return padWith.slice(0, targetLength) + String(str);
        }
    };
}
