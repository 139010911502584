import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericService } from '../providers/generic.service';
import { MENU } from '../../settings/menu';
import { Filters } from '../../data/data';

@Injectable()
export class GlobalService
{
    static path: string;
    static subscription: any;
    static ajaxSubscription: any = [];
    static base: any = 0;
    static data = Filters;
    static title: string;
    private static _defaultFilters: any[] =
        [
            //{ Id: "View Type", Title: "View Type", Selected: "", List: ["Overall", "Trend"], Multiple: false, Disabled:true },
            { Id: "City", Title: "City", Selected: ["All Cities"], List: [] },
            { Id: "Brand", Title: "Brand", Selected: [], List: [] },// Selected: '1', Clearable: false, List: [{ 'Id': '1', Title: "Nakheel Malls" }], Change: 'changeMallType', Visible: false },//Multiple: false
            { Id: "Wave", Title: "Wave", Selected: [], List: [] },
        ];

    static selectedFilters: string;

    static ajaxSubscriptionsMap = {};

    private static _titleObserver = new Subject();
    static titleObservable = GlobalService._titleObserver.asObservable();

    static changeTitle(newTitle: string)
    {
        this.title = newTitle;
        setTimeout(() =>
        {
            this._titleObserver.next(newTitle);
        });

        this.routeChange();
    }

    private static _filters: any[];
    static get filters()
    {
        if (!this._filters || this._filters.length == 0)
            this._filters = GenericService.clone(this._defaultFilters);

        this.dataBind();
        return this._filters;
    }

    public static getFilters()
    {
        return this._filters;
    }

    static dataBind()
    {

        this._filters.filter(x => x.Id != 'View Type').forEach(x =>
        {
            x.List = [];
        
        });

        if (!this.title || this.title.indexOf("Facing") >= 0)
        {
            this._filters.filter(x => x.Id != 'View Type').forEach(x =>
            {
                let dt = this.data.filter(flt => flt.Page == "Shelf" && flt.Type == x.Id);
                for (let n of dt)
                {
                    if (x.List.indexOf(n.Id) == -1)
                        x.List.push(n.Id);
                }
            });

        }
        else
        {
            this._filters.filter(x => x.Id != 'View Type').forEach(x =>
            {
                let dt = this.data.filter(flt => flt.Page != "Shelf" && flt.Type == x.Id);
                for (let n of dt)
                {
                    if (x.List.indexOf(n.Id) == -1)
                        x.List.push(n.Id);
                }
            });

        }


    }

    static set filters(filters)
    {
        this._filters = filters;
        this.emitFilterChanges();
    }

    private static _filtersObserver = new Subject();
    static filtersObservable = GlobalService._filtersObserver.asObservable();
    static emitFilterChanges(createClone = false)
    {
        if (createClone)
            this._filters = GenericService.clone(this.filters);
        this._filtersObserver.next(this.filters);
    }

    static areFiltersLoaded()
    {
        return this.filters && this.filters.length > 0 && this.filters[0].List && this.filters[0].List.length > 0;
    }

    static loadFilters(masterService)
    {
        let filterIds = this._defaultFilters.map(x => x.Id);

        masterService.callService("variable-label/list?variables=" + filterIds, true)
            .subscribe(
                (variableLabels) =>
                {
                    this.path = GenericService.getCurrentPath();
                    for (let f of this.filters)
                    {

                        let vl = variableLabels.find(x => x.Name == f.Id);
                        f.List = vl ? Object.keys(vl.ValueLabels).map(key =>
                        {
                            let obj: any = { Id: vl.ValueLabels[key].Value, Title: vl.ValueLabels[key].Label };
                            return obj;
                        }) : [];

                        f.Multiple = f.Multiple == undefined ? true : f.Multiple;
                        if (f.Pages && !f.Pages.find(x => x == this.path.replace("/", "")))
                            f.Visible = false;

                        f.Visible = f.Visible == undefined ? true : f.Visible;

                        f.Clearable = f.Clearable == undefined ? true : f.Clearable;
                        f.EnableSearch = false;

                        if (f.Change && GlobalService[f.Change] && f.Selected && f.Selected.length > 0)
                            GlobalService[f.Change](masterService);
                    }
                    let f = this.filters.find(x => x.Id == 'Wave');
                    //f.Selected = [String(this.getLatestWave())];
                    f.Selected = [f.List[f.List.length - 1].Id];
                    if (f.List.length > 1)
                        f.Selected.push(f.List[f.List.length - 2].Id);
                    if (f.List.length > 2)
                        f.Selected.push(f.List[f.List.length - 3].Id);
                    this.emitFilterChanges();
                });
    }

    static showHideFilters()
    {
        //for (let f of this.filters)
        //{
        //    f.Visible = true;
        //}
    }

    static resetFilters()
    {
        this.path = GenericService.getCurrentPath();
        for (let f of this.filters)
        {
            if (f.Visible)
            {
                let df = this._defaultFilters.find(x => x.Id == f.Id);
                f.Selected = df.Selected ? GenericService.clone(df.Selected) : null;
            }
        }
    }

    static getMongoFiltersObj(filters = null, excludedFilters = null)
    {
        this.getSelectedFilters();
        filters = filters || this.filters;
        var obj = { "$and": [] };
        for (let f of filters)
        {
            if (f.Selected && f.Visible != false)
            {
                let temp = {};
                if (excludedFilters && excludedFilters.find(x => x == f.Id))
                    continue;
                if (f.Multiple == false)
                    temp[f.Id] = this.toNumber(f.Selected);
                else
                {
                    if (f.Selected.length > 1)
                        temp[f.Id] = { $in: this.toNumber(f.Selected) };
                    else
                        temp[f.Id] = this.toNumber(f.Selected[0]);
                }
                obj["$and"].push(temp);
            }
        }

        if (obj["$and"].length == 0)
            return null;

        return obj;
    }

    static addToMongoFilters(mongoFilters, filtersToAdd)
    {
        if (!mongoFilters)
            mongoFilters = { "$and": [] };

        mongoFilters["$and"] = mongoFilters["$and"].concat(filtersToAdd);

        return mongoFilters;
    }

    static serializeFilters(filters = null, excludedFilters = null)
    {
        var obj = this.getMongoFiltersObj(filters, excludedFilters);
        if (!obj)
            return "";
        let str = JSON.stringify(obj);
        return str;
    }

    static toNumber(arrOrValue)
    {
        if (Array.isArray(arrOrValue))
            return arrOrValue.map(x => !isNaN(x) ? parseFloat(x) : x);
        else
            return !isNaN(arrOrValue) ? parseFloat(arrOrValue) : arrOrValue;
    }

    static getBase(masterService)
    {
        var filters = this.serializeFilters();
        var url = GenericService.addToQueryString('total', "filters", filters);
        let sub2 = masterService.callGetService(url)
            .subscribe((value) =>
            {
                this.base = value;
            },
                (err) =>
                {
                    console.log(err);
                });
    }

    static getSelectedFilters(filters = null)
    {

        filters = filters || this.filters;
        var strFilters = "";
        for (let f of filters)
        {
            if (f.List && f.List.length > 0 && f.Selected && f.Visible != false)
            {
                if (f.Multiple != false)
                {
                    if (f.Selected.length > 0)
                    {
                        let selected = f.List.filter(x => f.Selected.find(y => y == x.Id));
                        strFilters += " | <b>" + f.Title + ":</b> " + GenericService.toCsv(selected, 'Title');
                    }
                }
                else
                {
                    let val = f.List.find(x => f.Selected == x.Id);
                    strFilters += " | <b>" + f.Title + ":</b> " + val.Title;
                }
            }
        }
        if (strFilters.length > 0)
            strFilters = strFilters.substring(5);

        this.selectedFilters = strFilters

        return strFilters;
    }

    static reloadFilter(masterService, filterId, serviceName, filters = null)
    {
        if (this.ajaxSubscriptionsMap[filterId])
            this.ajaxSubscriptionsMap[filterId].unsubscribe();

        filters = filters || this.filters;
        let filter = filters.find(x => x.Id == filterId);
        var url = serviceName;
        var filterStr = this.serializeFilters(null, filters);
        url = GenericService.addToQueryString(url, 'filters', filterStr);

        this.ajaxSubscriptionsMap[filterId] = masterService.callService(url, true)
            .subscribe(
                (list) =>
                {
                    filter.List = list;
                    if (filter.List && filter.List.length > 0 && filter.Selected && filter.Selected.length > 0)
                    {
                        if (filter.Multiple == false)
                        {
                            let selected = list.find(x => x.Code == filter.Selected);
                            if (!selected)
                                filter.Selected = null;
                        }
                        else
                        {
                            filter.Selected = filter.Selected.filter(x => list.find(y => y.Id == x));
                        }
                    }
                });
    }

    static routeChange()
    {
        this.path = GenericService.getCurrentPath();
        this.showHideFilters();
    }

    static getMenuItemByPath(path: string)
    {
        for (let item of MENU)
        {
            if (item.routing == path)
                return item;
            if (item.sub)
            {
                for (let subItem of item.sub)
                {
                    if (subItem.routing == path)
                        return subItem;
                }
            }
        }
    }

    static getPageTitleByPath(path: string)
    {
        let item = this.getMenuItemByPath(path);
        return item ? item.title : '';
    }

    static unsubscribeAjax()
    {
        for (let i = 0; i < this.ajaxSubscription.length; i++)
        {
            if (this.ajaxSubscription[i])
                this.ajaxSubscription[i].unsubscribe();
        }
        this.ajaxSubscription = [];
    }

    static unsubscribe()
    {
        if (this.subscription)
            this.subscription.unsubscribe();

        this.unsubscribeAjax();
    }

    static getWaveName(wave)
    {
        wave = wave.toString();
        return 'Wave ' + parseInt(wave.substring(4, 6)) + "'" + wave.substring(2, 4);
    }

    static brandColors =
        {
            'Januvia': '#7FB3D5',
            'Galvus': '#AF7AC5',
            'Trajenta': '#7F8C8D',
            'Onglyza': '#E74C3C',
            'Gliptus': '#0000FF',
            'Vildagluse': '#F39C12',
            'Icandra': '#EDBB99',
            'Glipten': '#117A65',
            'Jalra': '#D98880',
            'Inhiglip': '#2E86C1',
            'Formigliptin': '#F4D03F',
            'Linajenta': '#71C272',
            'Vipidia': '#00FF00',
            'Vilgat': '#117A65',
            'Glaptivia': '#A04000',
            'Xelevia': '#17202A',
            'Janumet': '#F4D03F',
            'Galvusmet': '#7FB3D5',
            'Jentadueto': '#17202A',
            'Janumetxr': '#A04000',
            'Kombiglyzexr': '#00FF00',
            'Vipdomet': '#D98880',
            'Jalram': '#F39C12',
            'Velmetia': '#117A65',
            'Forziga': '#7F8C8D',
            'Forxiga': '#EDBB99',
            'Invokana': '#F4D03F',
            'Jardiance': '#0000FF',
            'Synjardy': '#7FB3D5',
            'Xigduo': '#F39C12',
            'Vokanamet': '#E74C3C',
            'Glyxambi': '#AF7AC5',
            'Tarceva (erlotinib)': '#7FB3D5',
            'Giotrif (afatinib)': '#17202A',
            'Iressa (gefitinib)': '#A04000',
            'Ertinob (erlotinib  Generic)': '#00FF00',
            'Osimertinib': '#D98880',

            'Ninetedanib': "#7030A0",
            'Pirfenidone': "#02AD50",
        }

    static keyColors =
        {
            'Ibn Battuta Mall': '#7030A0',
            'Dragon Mart 2': '#AF7AC5',
            'Dragon Mart 1': '#7F8C8D',
            'Golden Miles': '#02AD50',
            'Al Furjan Pavilion': '#0000FF',
            'Jumeirah Island Pavilion': '#F39C12',
            'Jumeirah Park Pavilion': '#EDBB99',
            'Discovery Gardens Pavilion': '#469990',
            'International City Pavilion': '#D98880',
            'The Pointe': '#2E86C1',
            'Mall of the Emirates': '#911eb4',
            'Dubai Marina Mall': '#71C272',
            'Festival City': '#00FF00',
            'Ajman China Mall': '#117A65',
            'Greens community centre': '#f032e6',
            'La Mer': '#aaffc3',
            'Al Seef': '#F4D03F',
            'Blue Waters': '#7FB3D5',
            'The Spring Souk': '#66cccc',
            'JBR THE WALK': '#A04000',
        }

    static wavesOrder =
        {
            "Wave 1'19": 1,
            "Wave 2'18": 2,
            "Wave 1'18": 3,
        }
}

