import { Component } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { ActivatedRoute } from "@angular/router";
import { ChartService } from 'app/providers/chart.service';
import { HttpClient } from '@angular/common/http';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { ManufacturerData,Total } from 'data/data';
import { ModalService } from 'app/providers/modal.service';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
    moduleId: module.id,
    selector: 'display',
    templateUrl: './display.component.html',
    styleUrls: ['./display.component.scss']
})

export class PageDisplayComponent
{
    title: string;
    gs = GlobalService;
    filters = [];
    mode = 'category';
    filteredItems: any = [];
    selectedTabIndex = 0;
    configs: any = [
        { id: 'chart3', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "mscolumn2d", title: '', Group: 'Overall' },
        { id: 'chart1', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "mscolumn2d", title: '', Group: 'AllTrend' },
        { id: 'chart2', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "mscolumn2d", title: '', Group: 'AllTrend' },
        { id: 'chart4', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "mscolumn2d", title: '', Group: 'SubTrend' },
    ]

    popupConfigs: any = []
    manufacturerData: any = [];
    totalData = [];
    filteredManufacturerDataAll = [];
    filteredManufacturerData = [];
    filteredTotalData = [];
    selectedManufacturer: string = "";
    mainConfigs = [];
    modalConfigs = [];
    constructor(private route: ActivatedRoute, private chartService: ChartService, private http: HttpClient, private modalService: ModalService)
    {
        this.gs.changeTitle('Display');
        this.manufacturerData = ManufacturerData;
        this.filters = this.gs.filters;
        this.filters.map(x =>
        {
            if (x.Id == "Wave")
                x.Selected.push("W4 2019 May");
            if (x.Id == "Brand")
                x.Selected = [];
            if (x.Id == "City")
                x.Selected = ["All Cities"];
                });
        this.totalData = Total;
        this.mainConfigs = this.configs.filter(x => x.Group != "SubTrend");
        this.onSequenceChangeEvent();

        GlobalService.filtersObservable.subscribe(filters =>
        {
            this.onSequenceChangeEvent();
        });
    }

    async ngOnInit()
    {
        this.manufacturerData = ManufacturerData;
        this.totalData = Total;
        
        FusionChartsService.clickObservable.subscribe(obj =>
        {
                this.selectedManufacturer = obj[Object.keys(obj).find(x => x == "categoryLabel")];
            this.modalConfigs = this.configs.filter(x => x.Group == "SubTrend");
           
            for (let config of this.modalConfigs)
            {
                this.getChart(config,);
            }
            this.openModal("custom-modal-1");
        });
    }

    ngOnDestroy()
    {
        this.gs.unsubscribe();
    }

    onSequenceChangeEvent()
    {
        this.getFilterdData();
        for (let config of this.mainConfigs)
        {
            this.getChart(config);
        }

    }
    getChart(config)
    {
        var chartData = [];
        var table = { Stubs: [], Banners: [] };
       
       
        if (config.id == "chart1")
        {
            chartData = this.filteredTotalData.filter(x => x.DataType == "Number");
            table = this.msColumnTotalChart(chartData);
        }
        else if (config.id == "chart2")
        {
            chartData = this.filteredTotalData.filter(x => x.DataType == "Percentage");
            chartData.forEach(x =>  delete x["Total Rice"]);
            table = this.msColumnTotalChart(chartData);
        
        }
        else if (config.id == "chart3")
        {
            chartData = this.filteredManufacturerDataAll;
            table = this.msColumnTotalChart(chartData);
        }
        else if (config.id == "chart4")
        {
            chartData = this.filteredManufacturerData.map(item =>
            {
                const obj = {};
                obj[this.selectedManufacturer] = item[this.selectedManufacturer];
                obj["Wave"] = item["Wave"];
                obj["City"] = item["City"];
                return obj;
            });
            // chartData.forEach(x => delete x["Total Rice"]);
            table = this.msColumnChart(chartData);

        }
     
        //let nestedArrays = table.Banners.map(x => x.Values.map(y => y.Perc));
        //let values = [].concat(...nestedArrays);
        //config.chartOptions.yAxisMinValue = Math.min.apply(Math, values) - 50;
        let wave = "";
        if (this.filters.filter(x => x.Id == "Wave")[0].Selected.length == 0)
        {
            let list = this.filters.filter(x => x.Id == "Wave")[0].List;
            wave = list[list.lenth-1];
        }
        else
            wave = this.filters.filter(x => x.Id == "Wave")[0].Selected[0];

        config.table = table;
        if (config.id == "chart1")
            config.title = "Total Number of Displays - " +wave;
        else if (config.id == "chart2")
            config.title = "Share of Display within Segments";
        else if (config.id == "chart3")
            config.title = "Share of Display by Manufacturer in Total Rice";
        else if (config.id == "chart4")
            config.title = this.selectedManufacturer ;
        console.log(config);
        //FusionChartsService.renderChart(config);
         FusionChartsService.renderChartFromTable(config);
    }

    msColumnChart(data)
    {
        let stubdt = Object.keys(data[0]).filter(x => x != "Wave" && x != "City" && x != "DataType");
        var table1 = { Stubs: [], Banners: [] };

        for (let banner = 0; banner < stubdt.length; banner++)
        {
            let value = [];
            for (let stub = 0; stub < data.length; stub++)
            {
                if (!table1.Stubs.find(t => t.Id == data[stub].Wave))
                    table1.Stubs.push({ Id: data[stub].Wave, Name: data[stub].Wave, Color: null, Base: null });

                if (data[stub][stubdt[banner]].length == 0)
                {
                    value.push({ Freq: 0, Perc: 0, Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + data[stub].Wave + "{br}Value:0" });// {br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });
                }
                else
                {
                    value.push({ Freq: data[stub][stubdt[banner]], Perc: data[stub][stubdt[banner]], Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + data[stub].Wave + "{br}Value:" + data[stub][stubdt[banner]] });// + "{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });//, Perc: parseInt(cd[0]["Share"])
                }
            }
            table1.Banners.push({ Id: stubdt[banner], Name: stubdt[banner], Color: null, Base: null, Values: value });
        }
        return table1;
    }

    msColumnTotalChart(data)
    {
        let stubdt = Object.keys(data[0]).filter(x => x != "Wave" && x != "City" && x != "DataType");
        var table1 = { Stubs: [], Banners: [] };

        for (let banner = 0; banner < data.length; banner++)
        {
            let value = [];
            for (let stub = 0; stub < stubdt.length; stub++)
            {
                if (!table1.Stubs.find(t => t.Id == stubdt[stub]))
                    table1.Stubs.push({ Id: stubdt[stub], Name: stubdt[stub], Color: null, Base: null });

                if (data[banner][stubdt[stub]].length == 0)
                {
                    value.push({ Freq: 0, Perc: 0, Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + stubdt[stub] + "{br}Value:0" });//
                }
                else
                {
                    value.push({ Freq: data[banner][stubdt[stub]], Perc: data[banner][stubdt[stub]], Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + stubdt[stub] + "{br}Value:" + data[banner][stubdt[stub]] });// + "{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });//, Perc: parseInt(cd[0]["Share"])
                }
            }
            table1.Banners.push({ Id: data[banner].Wave, Name: data[banner].Wave, Color: null, Base: null, Values: value });
        }
        return table1;
    }

    getRandomArbitrary(min, max)
    {
        return parseInt(Math.random() * (max - min) + min);
    }

    getRandomAMPM()
    {
        let arr = ['AM', 'PM'];
        var index = this.getRandomArbitrary(0, 2);
        return " " + arr[index];
    }

    getFilterdData()
    {
      
        this.filteredTotalData = this.totalData;
        this.filteredManufacturerDataAll = this.manufacturerData;
        this.filteredManufacturerData = this.manufacturerData;
        for (let flt of this.filters)
        {
            if (flt.Id == 'View Type')
                continue;

            var allKeys = Object.keys(this.filteredManufacturerDataAll[0]);

            if (flt.Id == 'Brand' && flt.Selected && flt.Selected.length > 0)
            {
                this.filteredManufacturerDataAll = this.filteredManufacturerDataAll.map(item =>
                {
                    const obj = {};
                    for (const slct of flt.Selected)
                    {
                        obj[slct] = item[slct];
                        obj["Wave"] = item["Wave"];
                        obj["City"] = item["City"];
                    }
                    return obj;
                })

                //this.filteredData = this.filteredData.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
            }
            else if (flt.Id == 'Wave' && flt.Selected && flt.Selected.length > 0)
            {
                this.filteredTotalData = this.filteredTotalData.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
                this.filteredManufacturerDataAll = this.filteredManufacturerDataAll.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
            }
            else if (flt.Selected && flt.Selected.length > 0)
            {
                this.filteredManufacturerData = this.filteredManufacturerData.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
                this.filteredManufacturerDataAll = this.filteredManufacturerDataAll.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
            }
        }
    }

    openModal(id: string)
    {
        this.modalService.open(id);
    }

    closeModal(id: string)
    {
        this.modalService.close(id);
    }
}

