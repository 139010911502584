﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NiComponentsModule } from '../ni-components/ni-components.module';

import
{
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { CustomComponentsModule } from "../custom-components/custom-components.module";
import { PipesModule } from "../pipes/pipes.module";
import { NgSelectModule } from '@ng-select/ng-select';
import { PageHomeComponent } from './home/home.component';
import { PageDisplayComponent } from './display/display.component';
import { PageLoginComponent } from './login/login.component';
import { ChartModalComponent } from './chart-modal/chart-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NiComponentsModule,

        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        CustomComponentsModule,
        PipesModule,
        NgSelectModule
    ],
    declarations: [
        PageHomeComponent,
        PageDisplayComponent,
        PageLoginComponent,
       ChartModalComponent
    ],
    exports: [
        PageHomeComponent,
        PageDisplayComponent,
        PageLoginComponent,
        ChartModalComponent
    ],
    entryComponents: [
    ]
})
export class PagesModule { }
