import { Component } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { ActivatedRoute } from "@angular/router";
import { ChartService } from 'app/providers/chart.service';
import { HttpClient } from '@angular/common/http';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { ShelfData } from 'data/data';
import { ModalService } from 'app/providers/modal.service';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
    moduleId: module.id,
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class PageHomeComponent
{
    title: string;
    gs = GlobalService;
    mode = 'category';
    data: any = [];
    filteredItems: any = [];
    selectedTabIndex = 0;
    configs: any = [{ id: 'chart1', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "doughnut2d", title: '', Group: 'Overall' },
        { id: 'chart2', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "msline", title: '', Group: 'AllTrend' },
        { id: 'chart3', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "mscolumn2d", title: '', Group: 'SubTrend' }
    ]

    popupConfigs: any = []
    filteredData = [];
    selectedManufacturer: string = "";
    mainConfigs = [];
    modalConfigs = [];
    filters: any=[]; 
    constructor(private route: ActivatedRoute, private chartService: ChartService, private http: HttpClient, private modalService: ModalService)
    {
        this.gs.changeTitle('Share Of Facing');
        this.data = ShelfData;
        this.filters = this.gs.filters;
        this.filters.map(x =>
        {
            if (x.Id == "Wave")
                x.Selected = [];
            if (x.Id == "Brand")
                x.Selected = [];
            if (x.Id == "City")
                x.Selected = ["All Cities"];
        });
        this.mainConfigs = this.configs.filter(x => x.Group != "SubTrend");
        this.onSequenceChangeEvent();
     

        GlobalService.filtersObservable.subscribe(filters =>
        {
            this.onSequenceChangeEvent();
        });
    }

    async ngOnInit()
    {
        this.data = ShelfData; 
        FusionChartsService.clickObservable.subscribe(obj =>
        {
            if (Object.keys(obj).find(x => x == "datasetName"))
                this.selectedManufacturer = obj[Object.keys(obj).find(x => x == "datasetName")];
            else
                this.selectedManufacturer = obj[Object.keys(obj).find(x => x == "categoryLabel")];
       
            this.modalConfigs = this.configs.filter(x => x.Group == "SubTrend");
           
            for (let config of this.modalConfigs)
            {
                this.getChart(config,);
            }
            this.openModal("custom-modal-1");
        });
    }

    ngOnDestroy()
    {
        this.gs.unsubscribe();
    }

    onSequenceChangeEvent()
    {
        this.getFilterdData();
        for (let config of this.mainConfigs)
        {
            this.getChart(config);
        }

    }
    getChart(config)
    {
        var chartData = [];
        var table = { Stubs: [], Banners: [] };
        
       
        if (config.id == "chart1")
        {
            if (this.filters.filter(x => x.Id == "Wave")[0].Selected.length == 0)
                chartData = this.filteredData.filter(f => f.Wave == this.filters.filter(x => x.Id == "Wave")[0].List[5]);
            else
                chartData = this.filteredData.filter(f => f.Wave == this.filters.filter(x => x.Id == "Wave")[0].Selected[0]);
            table = this.doughnut(chartData);
        }
        else if (config.id == "chart2")
        {
            chartData = this.filteredData;
            table = this.barChart(chartData);
        }
        else if (config.id == "chart3")
        {


            if (this.filters.filter(x => x.Id == "Wave")[0].Selected.length == 0)
                chartData = this.data.filter(f => f.Wave == this.filters.filter(x => x.Id == "Wave")[0].List[5]);
            else
                chartData = this.data.filter(f => f.Wave == this.filters.filter(x => x.Id == "Wave")[0].Selected[0]);

            chartData = chartData.map(item =>
            {
                const obj = {};
                obj[this.selectedManufacturer] = item[this.selectedManufacturer];
                    obj["Wave"] = item["Wave"];
                    obj["City"] = item["City"];
                return obj;
            });

            table = this.msColumnChart(chartData);
        }

        //let nestedArrays = table.Banners.map(x => x.Values.map(y => y.Perc));
        //let values = [].concat(...nestedArrays);
        //config.chartOptions.yAxisMinValue = Math.min.apply(Math, values) - 50;
        let wave = "";
        if (this.filters.filter(x => x.Id == "Wave")[0].Selected.length == 0)
            wave = this.filters.filter(x => x.Id == "Wave")[0].List[5];
        else
            wave = this.filters.filter(x => x.Id == "Wave")[0].Selected[0];

        config.table = table;
        if (config.id == "chart1")
            config.title = "All Brands - " + table.Banners[0].Name;
        else if (config.id == "chart3")
            config.title = this.selectedManufacturer + " " + wave+" - City Break";//+" manufacturer into brands";
            else
            config.title = "All Brands";
        console.log(config);
        //FusionChartsService.renderChart(config);
         FusionChartsService.renderChartFromTable(config);
    }

    doughnut(data)
    {
        let bannerName = "Wave";
        let stubName = "Brand"
        //filters.filter(x => x.Id == "Wave")[0].List[5])
       // let bannerdt = this.gs.filters.find(x => x.Id ).List;
        //let stubdt = this.gs.filters.find(x => x.Id == stubName).List;
        let stubdt = Object.keys(data[0]).filter(x => x != "Wave" && x != "City");
        var table2 = { Stubs: [], Banners: [] };

        for (let banner = 0; banner < data.length;banner++)
        {
            let value = [];
            for (let stub = 0; stub < stubdt.length;stub++)
            {
                if (!table2.Stubs.find(t => t.Id == stubdt[stub]))
                    table2.Stubs.push({ Id: stubdt[stub], Name: stubdt[stub], Color: null, Base: null });

                if (data[banner][stubdt[stub]].length == 0)
                {
                    value.push({ Freq: 0, Perc: 0, Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + stubdt[stub] + "{br}Value:0" });// {br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });
                }
                else
                {
                    value.push({ Freq: data[banner][stubdt[stub]], Perc: data[banner][stubdt[stub]], Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + stubdt[stub] + "{br}Value:" + data[banner][stubdt[stub]] });// +"{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });//, Perc: parseInt(cd[0]["Share"])
                }
            }
            table2.Banners.push({ Id: data[banner][bannerName], Name: data[banner][bannerName], Color: null, Base: null, Values: value });
        }
        return table2;
    }

    barChart(data)
    {
        let bannerName = "Wave";
        let stubName = "Brand"
        //filters.filter(x => x.Id == "Wave")[0].List[5])
        //let bannerdt = this.gs.filters.find(x => x.Id).List;
        //let stubdt = this.gs.filters.find(x => x.Id == stubName).List;
        let stubdt = Object.keys(data[0]).filter(x => x != "Wave" && x != "City");
        var table1 = { Stubs: [], Banners: [] };

        for (let banner = 0; banner < stubdt.length; banner++)
        {
            let value = [];
            for (let stub = 0; stub < data.length; stub++)
            {
                if (!table1.Stubs.find(t => t.Id == data[stub].Wave))
                    table1.Stubs.push({ Id: data[stub].Wave, Name: data[stub].Wave, Color: null, Base: null });

                if (data[stub][stubdt[banner]].length == 0)
                {
                    value.push({ Freq: 0, Perc: 0, Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + data[stub].Wave + "{br}Value:0 " });//{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });
                }
                else
                {
                    value.push({ Freq: data[stub][stubdt[banner]], Perc: data[stub][stubdt[banner]], Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + data[stub].Wave + "{br}Value:" + data[stub][stubdt[banner]] });//+ "{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });//, Perc: parseInt(cd[0]["Share"])
                }
            }
            table1.Banners.push({ Id: stubdt[banner], Name: stubdt[banner], Color: null, Base: null, Values: value });
        }
        return table1;
    }

    msColumnChart(data)
    {
        let bannerName = "Wave";
        let stubName = "Brand"
        //filters.filter(x => x.Id == "Wave")[0].List[5])
        //let bannerdt = this.gs.filters.find(x => x.Id).List;
        //let stubdt = this.gs.filters.find(x => x.Id == stubName).List;
        let stubdt = Object.keys(data[0]).filter(x => x != "Wave" && x != "City");
        var table1 = { Stubs: [], Banners: [] };

        for (let banner = 0; banner < stubdt.length; banner++)
        {
            let value = [];
            for (let stub = 0; stub < data.length; stub++)
            {
                if (!table1.Stubs.find(t => t.Id == data[stub].Wave))
                    table1.Stubs.push({ Id: data[stub].City, Name: data[stub].City, Color: null, Base: null });

                if (data[stub][stubdt[banner]].length == 0)
                {
                    value.push({ Freq: 0, Perc: 0, Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + data[stub].Wave + "{br}Value:0" });// {br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });
                }
                else
                {
                    value.push({ Freq: data[stub][stubdt[banner]], Perc: data[stub][stubdt[banner]], Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + data[stub].Wave + "{br}Value:" + data[stub][stubdt[banner]] });// + "{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });//, Perc: parseInt(cd[0]["Share"])
                }
            }
            table1.Banners.push({ Id: stubdt[banner], Name: stubdt[banner], Color: null, Base: null, Values: value });
        }
        return table1;
    }

    getRandomArbitrary(min, max)
    {
        return parseInt(Math.random() * (max - min) + min);
    }

    getRandomAMPM()
    {
        let arr = ['AM', 'PM'];
        var index = this.getRandomArbitrary(0, 2);
        return " " + arr[index];
    }

    getFilterdData()
    {
       
        this.filteredData = this.data;
        for (let flt of this.filters)
        {
            if (flt.Id == 'View Type')
                continue;

            var allKeys = Object.keys(this.filteredData[0]);

            if (flt.Id == 'Brand' && flt.Selected && flt.Selected.length > 0)
            {
               this.filteredData = this.filteredData.map(item =>
                {
                    const obj = {};
                    for (const slct of flt.Selected)
                    {
                        obj[slct] = item[slct];
                        obj["Wave"] = item["Wave"];
                        obj["City"] = item["City"];
                    }
                    return obj;
                })

                //this.filteredData = this.filteredData.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
            }
            else if (flt.Selected && flt.Selected.length > 0)
            {
                this.filteredData = this.filteredData.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
            }
        }
    }

    openModal(id: string)
    {
        this.modalService.open(id);
    }

    closeModal(id: string)
    {
        this.modalService.close(id);
    }
}

