import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { ChartService } from './chart.service';
import { Subject } from 'rxjs';

declare var FusionCharts: any;

@Injectable()
export class FusionChartsService
{
    private static _clickObserver = new Subject();
    static clickObservable = FusionChartsService._clickObserver.asObservable();

    static renderChart(config: any)
    {
        config.table = config.table || { Banners: [], Stubs: [] };
        let fc: any = { categories: [{ category: [] }], dataSet: [] };
        fc.chart =
            {
                labelFontColor: "#000",
                legendItemFontColor: "#000",
                legendItemFontSize: "11",
                pieRadius: "90",
                theme: "fint",
                showLabels: "1",
                showYAxisValues: "1",
                legendPosition: "bottom",
                legendAllowDrag: "0",
                showBorder: "0",
                showLegend: "1",
                showValues: "1",
                bgAlpha: "0",
                canvasBgAlpha: "0",
                toolTipBgColor: "#000000",
                rotateValues: "0",
                decimals: 0,
                showPercentValues: "1",
                labelFontBold: "0",
                numberSuffix: '%',
                labelFontSize: "12",
                valueFontSize: "13",
                enableSmartLabels: "1",
                //yAxisMaxValue: "100",
                valueBgHoverAlpha: "5",
                placeValuesInside: "0",
                valueFontColor: "#000"
            };

        if (config.chartOptions)
            fc.chart = Object.assign(fc.chart, config.chartOptions);

        config.table.Stubs.forEach(x =>
        {
            fc.categories[0].category.push({ "label": x.Name, "color": x.Color });
        });

        config.table.Banners.forEach((banner, i) =>
        {
            let color = banner.Color || ChartService.getColor(i);

            let ds: any = {
                seriesName: banner.Name,
                color: color,
                data: banner.Values.map((x,i) =>
                {
                  
                    //let toolText = "";
                    //if (x.Series)
                    //    toolText += "Series: " + x.Series + "{br}";
                    //if (x.Category)
                    //    toolText += "Data Point: " + x.Category + "{br}";
                    //toolText += "Percentage: " + GenericService.round(x.Perc, 2) + "% {br}";

                    //if (x.Freq)
                    //    toolText += "Frequency: " + GenericService.round(x.Freq, 2) + "{br}";

                    //if (x.Base)
                    //    toolText += "Base: " + GenericService.round(x.Base, 2) + "{br}";

                    let obj: any = {
                        value: x.Perc || x.Freq,
                        //toolText: toolText
                    };
                    if (x.ToolTip)
                        obj.tooltext = x.ToolTip;
                     //obj.color = x.Color ;

                    let displayvalue: any = GenericService.round(x.Perc, 0);
                    //if (x.Perc < 1 && config.type != "pie2d")
                    //{
                    //  displayvalue = ' ';
                    //  obj.displayvalue = displayvalue
                    //};
                    return obj;
                }),
            };
            for (let i = 0; i < fc.categories[0].category.length; i++)
            {
                if (config.type == "pie2d" || config.type == "doughnut2d")
                    ds.data[i].label = fc.categories[0].category[i].label;

                ds.data[i].color = fc.categories[0].category[i].color || ds.data[i].color;
                if (config.type == "pie2d" || config.type == "doughnut2d")
                {
                    ds.data[i].color = ds.data[i].color || ChartService.getColor(i);
                }
            }
            fc.dataSet.push(ds);
        });

        config.width = config.width ? config.width : "100%";
        config.height = config.height ? config.height : "300px";
        if (config.height.indexOf('vh') > -1)
        {
            let height = config.height.replace('vh', '');
            config.height = (window.innerHeight * height / 100) + 'px';
        }
        return this.renderChartByID(config, fc, config.id, config.width, config.height);
    }

    static renderChartByID(config, data, id, width = "100%", height = "250px")
    {
        return FusionCharts.ready(function ()
        {
            if (document.getElementById(id))
            {
                var chart = new FusionCharts({
                    type: config.type,
                    renderAt: id,
                    width: width,
                    height: height,
                    containerBackgroundOpacity: 0,
                    dataFormat: 'json',
                    dataSource: data,
                    
                    'events': {
                        'dataplotClick': function (eventObj, dataObj)
                        {
                          //  if (config.Group.indexOf("Sub")!=0)
                            FusionChartsService._clickObserver.next(dataObj);
                        }
                    }
                });

                chart.render();
            }
        });
    }

    static defaultOptions =
        {
            labelFontColor: "#000",
            baseFontColor: "#fff",
            legendItemFontColor: "#000",
            legendItemFontSize: "12",
            pieRadius: "90",
            theme: "fint",
            showLabels: "1",
            showYAxisValues: "1",
            legendPosition: "bottom",
            legendAllowDrag: "0",
            showBorder: "0",
            showLegend: "1",
            showValues: "1",
            bgAlpha: "0",
            canvasBgAlpha: "0",
            toolTipBgColor: "#000000",
            rotateValues: "0",
            decimals: 0,
            showPercentValues: "1",
            labelFontBold: "0",
            numberSuffix: '%',
            labelFontSize: "12",
            valueFontSize: "12",
            enableSmartLabels: "1",
            yAxisMaxValue: "120",
            valueBgHoverAlpha: "5",
            placeValuesInside: "0",
            valueFontColor: "#000",
            numDivLines: 0,
            divLineAlpha: 3//To remove top div line
        };

  
    static setHeightWidth(config)
    {
        config.width = config.width ? config.width : "100%";
        config.height = config.height ? config.height : "300px";
        if (config.height.indexOf('vh') > -1)
        {
            let height = config.height.replace('vh', '');
            config.height = (window.innerHeight * height / 100) + 'px';
        }
    }

    static renderChartSS(config: any)
    {
        let fc: any = {};
        fc.chart = GenericService.clone(this.defaultOptions);
        if (config.chartOptions)
            fc.chart = Object.assign(fc.chart, config.chartOptions);

        fc.data = config.data;
        this.setHeightWidth(config);
        return this.renderChartById(config.type, fc, config.id, config.width, config.height);
    }

    static renderChartFromTable(config: any, perc = true)
    {
        //if (config.id == "chart1")
        //    return this.renderChartByIdTest("chart1");

        config.table = config.table || { Banners: [], Stubs: [] };
        let fc: any = { categories: [{ category: [] }], dataSet: [] };
        fc.chart = GenericService.clone(this.defaultOptions);

        if (config.fc)
            fc = Object.assign(fc, config.fc);

        if (config.chartOptions)
            fc.chart = Object.assign(fc.chart, config.chartOptions);

        if (config.annotations)
            fc.annotations = config.annotations;

        config.table.Stubs.forEach(x =>
        {
            fc.categories[0].category.push({ "label": x.Name, "color": x.Color, "id": x.Id });
        });

        config.table.Banners.forEach((banner, i) =>
        {
            let color = config.table.Banners.length == 1 ? (banner.Color || ChartService.getColor(i + 1)) : (banner.Color || ChartService.getColor(i));

            let ds: any = {
                id: banner.Id,
                seriesName: banner.Name,
                color: color,
                data: banner.Values.map((x, i) =>
                {
                    let toolText = "";
                    if (x.Series || banner.Name)
                        toolText += "Series: " + (x.Series || banner.Name) + "{br}";
                    if (x.Category || (config.table.Stubs[i] && config.table.Stubs[i].Name))
                        toolText += "Data Point: " + (x.Category || config.table.Stubs[i].Name) + "{br}";

                    if (perc)
                    {
                        if (fc.chart.numberSuffix == '%')
                            toolText += "Percentage: " + GenericService.round(x.Perc, 2) + "% {br}";
                        else
                            toolText += "Avg: " + GenericService.round(x.Perc, 2) + " {br}";
                    }
                    //if (x.Freq)
                    //  toolText += "Frequency: " + GenericService.round(x.Freq, 2) + "{br}";

                    if (x.Base)
                        toolText += "Base: " + GenericService.round(x.BaseTotal, 2) + "{br}";

                    let obj: any = {
                        value: perc ? x.Perc : x.Freq,
                        toolText: toolText,
                    };
                    if (x.ToolTip)
                        obj.tooltext = x.ToolTip;

                    let displayvalue: any = GenericService.round(x.Perc, 0);
                    if (x.Perc < 1 && config.type != "pie2d")
                    {
                        displayvalue = ' ';
                        obj.displayvalue = displayvalue
                    };

                    return obj;
                }),
            };
            for (let i = 0; i < fc.categories[0].category.length; i++)
            {
                if (config.type == "pie2d" || config.type == "doughnut2d")
                    ds.data[i].label = fc.categories[0].category[i].label;

                ds.data[i].color = fc.categories[0].category[i].color || ds.data[i].color;
                if (config.type == "pie2d" || config.type == "doughnut2d")
                {
                    ds.data[i].color = ds.data[i].color || ChartService.getColor(i);
                }
            }
            fc.dataSet.push(ds);
        });

        this.setHeightWidth(config);
        return this.renderChartById(config, fc, config.id, config.width, config.height);
    }

    static renderChartById(config, fc, id, width = "100%", height = "250px")
    {
        return FusionCharts.ready(function ()
        {
            if (document.getElementById(id))
            {
                let chart = new FusionCharts({
                    type: config.type,
                    renderAt: id,
                    width: width,
                    height: height,
                    containerBackgroundOpacity: 0,
                    dataFormat: 'json',
                    dataSource: fc,
                    'events': {
                        'dataplotClick': function (eventObj, dataObj)
                        {
                            if (config.Group=="Overall")
                            FusionChartsService._clickObserver.next(dataObj);
                        }
                    }
                    //'events': {
                    //    'dataplotClick': function (eventObj, dataObj)
                    //    {
                    //        let obj: any = {};
                    //        obj.stubId = eventObj.sender.args.dataSource.categories[0].category[dataObj.dataIndex].id;
                    //        obj.bannerId = eventObj.sender.args.dataSource.dataSet[dataObj.datasetIndex].id;
                    //        FusionChartsService._clickObserver.next(obj);
                    //    }
                    //}
                });

                chart.render();
            }
        });
    }

}
