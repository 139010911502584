export const ShelfData:any= [
    {
        "City": "All Cities",
        "Wave": "W1 2019 May",
        "AlWalimah(O)": "7.2%",
        "AlWalimah(S)": "5.0%",
        "Total AlWalimah": "12.2%",
        "AbuKass": "6.6%",
        "Tejan": "0.6%",
        "AlShalan": "4.5%",
        "AlShalan Punjabi": "1.8%",
        "Al Aila": "5.2%",
        "Punjabi Al Muhaidib": "9.5%",
        "BabAlHind": "0.7%",
        "White Swan": "6.3%",
        "Green Farms": "2.5%",
        "SunWhite": "7.1%",
        "Others": "30.9%"
    },
    {
        "City": "All Cities",
        "Wave": "W2 2019 May",
        "AlWalimah(O)": "6.4%",
        "AlWalimah(S)": "5.3%",
        "Total AlWalimah": "11.7%",
        "AbuKass": "7.3%",
        "Tejan": "0.7%",
        "AlShalan": "4.3%",
        "AlShalan Punjabi": "2.4%",
        "Al Aila": "5.4%",
        "Punjabi Al Muhaidib": "9.3%",
        "BabAlHind": "0.7%",
        "White Swan": "5.8%",
        "Green Farms": "2.6%",
        "SunWhite": "7.3%",
        "Others": "30.6%"
    },
    {
        "City": "All Cities",
        "Wave": "W3 2019 May",
        "AlWalimah(O)": "8.1%",
        "AlWalimah(S)": "6.3%",
        "Total AlWalimah": "14.4%",
        "AbuKass": "12.7%",
        "Tejan": "1.6%",
        "AlShalan": "3.6%",
        "AlShalan Punjabi": "1.1%",
        "Al Aila": "7.4%",
        "Punjabi Al Muhaidib": "10.4%",
        "BabAlHind": "1.5%",
        "White Swan": "6.1%",
        "Green Farms": "2.2%",
        "SunWhite": "6.7%",
        "Others": "17.9%"
    },
    {
        "City": "All Cities",
        "Wave": "W4 2019 May",
        "AlWalimah(O)": "7.4%",
        "AlWalimah(S)": "5.2%",
        "Total AlWalimah": "12.5%",
        "AbuKass": "7.3%",
        "Tejan": "0.9%",
        "AlShalan": "4.9%",
        "AlShalan Punjabi": "1.9%",
        "Al Aila": "5.7%",
        "Punjabi Al Muhaidib": "9.4%",
        "BabAlHind": "0.6%",
        "White Swan": "5.7%",
        "Green Farms": "2.1%",
        "SunWhite": "6.9%",
        "Others": "29.5%"
    },
    {
        "City": "All Cities",
        "Wave": "W1 2019 Jun",
        "AlWalimah(O)": "7.9%",
        "AlWalimah(S)": "4.9%",
        "Total AlWalimah": "12.8%",
        "AbuKass": "7.0%",
        "Tejan": "0.9%",
        "AlShalan": "5.0%",
        "AlShalan Punjabi": "1.6%",
        "Al Aila": "5.6%",
        "Punjabi Al Muhaidib": "9.8%",
        "BabAlHind": "0.6%",
        "White Swan": "5.8%",
        "Green Farms": "2.0%",
        "SunWhite": "7.2%",
        "Others": "28.8%"
    },
    {
        "City": "All Cities",
        "Wave": "W2 2019 Jun",
        "AlWalimah(O)": "7.5%",
        "AlWalimah(S)": "5.4%",
        "Total AlWalimah": "12.9%",
        "AbuKass": "6.5%",
        "Tejan": "1.1%",
        "AlShalan": "3.9%",
        "AlShalan Punjabi": "1.9%",
        "Al Aila": "5.9%",
        "Punjabi Al Muhaidib": "9.7%",
        "BabAlHind": "0.6%",
        "White Swan": "5.6%",
        "Green Farms": "2.2%",
        "SunWhite": "7.4%",
        "Others": "29.3%"
    },
    {
        "City": "RIYADH",
        "Wave": "W1 2019 May",
        "AlWalimah(O)": "7.3%",
        "AlWalimah(S)": "4.0%",
        "Total AlWalimah": "11.3%",
        "AbuKass": "11.0%",
        "Tejan": "1.0%",
        "AlShalan": "4.5%",
        "AlShalan Punjabi": "1.9%",
        "Al Aila": "5.1%",
        "Punjabi Al Muhaidib": "7.9%",
        "BabAlHind": "1.0%",
        "White Swan": "5.6%",
        "Green Farms": "2.4%",
        "SunWhite": "6.8%",
        "Others": "30.0%"
    },
    {
        "City": "RIYADH",
        "Wave": "W2 2019 May",
        "AlWalimah(O)": "6.7%",
        "AlWalimah(S)": "4.0%",
        "Total AlWalimah": "10.7%",
        "AbuKass": "11.0%",
        "Tejan": "1.1%",
        "AlShalan": "4.7%",
        "AlShalan Punjabi": "2.6%",
        "Al Aila": "5.1%",
        "Punjabi Al Muhaidib": "7.3%",
        "BabAlHind": "1.0%",
        "White Swan": "5.5%",
        "Green Farms": "2.5%",
        "SunWhite": "6.9%",
        "Others": "30.9%"
    },
    {
        "City": "RIYADH",
        "Wave": "W3 2019 May",
        "AlWalimah(O)": "10.1%",
        "AlWalimah(S)": "5.0%",
        "Total AlWalimah": "15.1%",
        "AbuKass": "15.1%",
        "Tejan": "0.9%",
        "AlShalan": "4.4%",
        "AlShalan Punjabi": "0.9%",
        "Al Aila": "4.7%",
        "Punjabi Al Muhaidib": "8.5%",
        "BabAlHind": "1.3%",
        "White Swan": "5.0%",
        "Green Farms": "4.7%",
        "SunWhite": "6.3%",
        "Others": "17.9%"
    },
    {
        "City": "RIYADH",
        "Wave": "W4 2019 May",
        "AlWalimah(O)": "7.3%",
        "AlWalimah(S)": "4.9%",
        "Total AlWalimah": "12.2%",
        "AbuKass": "10.4%",
        "Tejan": "1.3%",
        "AlShalan": "4.9%",
        "AlShalan Punjabi": "2.1%",
        "Al Aila": "5.7%",
        "Punjabi Al Muhaidib": "7.4%",
        "BabAlHind": "0.9%",
        "White Swan": "5.1%",
        "Green Farms": "2.4%",
        "SunWhite": "6.5%",
        "Others": "28.8%"
    },
    {
        "City": "RIYADH",
        "Wave": "W1 2019 Jun",
        "AlWalimah(O)": "8.4%",
        "AlWalimah(S)": "4.4%",
        "Total AlWalimah": "12.7%",
        "AbuKass": "10.4%",
        "Tejan": "1.4%",
        "AlShalan": "5.4%",
        "AlShalan Punjabi": "1.7%",
        "Al Aila": "5.8%",
        "Punjabi Al Muhaidib": "8.1%",
        "BabAlHind": "0.9%",
        "White Swan": "5.4%",
        "Green Farms": "2.4%",
        "SunWhite": "6.6%",
        "Others": "26.6%"
    },
    {
        "City": "RIYADH",
        "Wave": "W2 2019 Jun",
        "AlWalimah(O)": "8.6%",
        "AlWalimah(S)": "4.8%",
        "Total AlWalimah": "13.4%",
        "AbuKass": "9.7%",
        "Tejan": "1.7%",
        "AlShalan": "4.2%",
        "AlShalan Punjabi": "2.1%",
        "Al Aila": "6.6%",
        "Punjabi Al Muhaidib": "7.8%",
        "BabAlHind": "0.8%",
        "White Swan": "5.3%",
        "Green Farms": "2.2%",
        "SunWhite": "6.7%",
        "Others": "26.1%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W1 2019 May",
        "AlWalimah(O)": "6.0%",
        "AlWalimah(S)": "5.4%",
        "Total AlWalimah": "11.4%",
        "AbuKass": "3.4%",
        "Tejan": "0.1%",
        "AlShalan": "2.8%",
        "AlShalan Punjabi": "2.8%",
        "Al Aila": "6.1%",
        "Punjabi Al Muhaidib": "10.5%",
        "BabAlHind": "0.4%",
        "White Swan": "6.3%",
        "Green Farms": "2.0%",
        "SunWhite": "7.7%",
        "Others": "35.2%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W2 2019 May",
        "AlWalimah(O)": "5.8%",
        "AlWalimah(S)": "5.4%",
        "Total AlWalimah": "11.2%",
        "AbuKass": "4.4%",
        "Tejan": "0.2%",
        "AlShalan": "3.2%",
        "AlShalan Punjabi": "3.5%",
        "Al Aila": "5.8%",
        "Punjabi Al Muhaidib": "10.6%",
        "BabAlHind": "0.6%",
        "White Swan": "6.0%",
        "Green Farms": "2.3%",
        "SunWhite": "8.0%",
        "Others": "33.1%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W3 2019 May",
        "AlWalimah(O)": "10.3%",
        "AlWalimah(S)": "7.6%",
        "Total AlWalimah": "17.9%",
        "AbuKass": "12.4%",
        "Tejan": "2.1%",
        "AlShalan": "3.4%",
        "AlShalan Punjabi": "0.7%",
        "Al Aila": "9.0%",
        "Punjabi Al Muhaidib": "12.4%",
        "BabAlHind": "1.4%",
        "White Swan": "5.5%",
        "Green Farms": "1.4%",
        "SunWhite": "6.2%",
        "Others": "9.7%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W4 2019 May",
        "AlWalimah(O)": "6.8%",
        "AlWalimah(S)": "5.0%",
        "Total AlWalimah": "11.9%",
        "AbuKass": "4.5%",
        "Tejan": "0.3%",
        "AlShalan": "2.9%",
        "AlShalan Punjabi": "3.3%",
        "Al Aila": "7.4%",
        "Punjabi Al Muhaidib": "10.6%",
        "BabAlHind": "0.5%",
        "White Swan": "6.6%",
        "Green Farms": "2.3%",
        "SunWhite": "8.4%",
        "Others": "29.6%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W1 2019 Jun",
        "AlWalimah(O)": "6.5%",
        "AlWalimah(S)": "5.5%",
        "Total AlWalimah": "12.0%",
        "AbuKass": "4.9%",
        "Tejan": "0.3%",
        "AlShalan": "3.4%",
        "AlShalan Punjabi": "2.7%",
        "Al Aila": "6.6%",
        "Punjabi Al Muhaidib": "10.7%",
        "BabAlHind": "0.3%",
        "White Swan": "6.6%",
        "Green Farms": "2.1%",
        "SunWhite": "8.6%",
        "Others": "29.8%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W2 2019 Jun",
        "AlWalimah(O)": "6.2%",
        "AlWalimah(S)": "5.1%",
        "Total AlWalimah": "11.3%",
        "AbuKass": "4.4%",
        "Tejan": "0.7%",
        "AlShalan": "3.2%",
        "AlShalan Punjabi": "2.8%",
        "Al Aila": "6.3%",
        "Punjabi Al Muhaidib": "11.6%",
        "BabAlHind": "0.3%",
        "White Swan": "6.9%",
        "Green Farms": "2.1%",
        "SunWhite": "8.7%",
        "Others": "30.3%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W1 2019 May",
        "AlWalimah(O)": "7.7%",
        "AlWalimah(S)": "5.7%",
        "Total AlWalimah": "13.4%",
        "AbuKass": "4.5%",
        "Tejan": "0.6%",
        "AlShalan": "5.6%",
        "AlShalan Punjabi": "1.1%",
        "Al Aila": "4.6%",
        "Punjabi Al Muhaidib": "10.4%",
        "BabAlHind": "0.7%",
        "White Swan": "6.8%",
        "Green Farms": "2.8%",
        "SunWhite": "6.9%",
        "Others": "29.2%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W2 2019 May",
        "AlWalimah(O)": "6.6%",
        "AlWalimah(S)": "6.6%",
        "Total AlWalimah": "13.1%",
        "AbuKass": "5.7%",
        "Tejan": "0.6%",
        "AlShalan": "4.7%",
        "AlShalan Punjabi": "1.5%",
        "Al Aila": "5.4%",
        "Punjabi Al Muhaidib": "10.4%",
        "BabAlHind": "0.6%",
        "White Swan": "5.8%",
        "Green Farms": "2.9%",
        "SunWhite": "7.3%",
        "Others": "28.8%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W3 2019 May",
        "AlWalimah(O)": "5.6%",
        "AlWalimah(S)": "6.9%",
        "Total AlWalimah": "12.5%",
        "AbuKass": "11.0%",
        "Tejan": "2.0%",
        "AlShalan": "3.1%",
        "AlShalan Punjabi": "1.3%",
        "Al Aila": "8.9%",
        "Punjabi Al Muhaidib": "11.2%",
        "BabAlHind": "1.8%",
        "White Swan": "7.1%",
        "Green Farms": "0.5%",
        "SunWhite": "7.1%",
        "Others": "20.9%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W4 2019 May",
        "AlWalimah(O)": "7.7%",
        "AlWalimah(S)": "5.6%",
        "Total AlWalimah": "13.2%",
        "AbuKass": "5.9%",
        "Tejan": "0.8%",
        "AlShalan": "5.9%",
        "AlShalan Punjabi": "1.0%",
        "Al Aila": "4.9%",
        "Punjabi Al Muhaidib": "10.5%",
        "BabAlHind": "0.3%",
        "White Swan": "5.7%",
        "Green Farms": "1.8%",
        "SunWhite": "6.6%",
        "Others": "30.1%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W1 2019 Jun",
        "AlWalimah(O)": "8.2%",
        "AlWalimah(S)": "5.2%",
        "Total AlWalimah": "13.4%",
        "AbuKass": "5.1%",
        "Tejan": "0.8%",
        "AlShalan": "5.5%",
        "AlShalan Punjabi": "0.9%",
        "Al Aila": "4.9%",
        "Punjabi Al Muhaidib": "10.9%",
        "BabAlHind": "0.4%",
        "White Swan": "5.7%",
        "Green Farms": "1.6%",
        "SunWhite": "6.8%",
        "Others": "30.4%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W2 2019 Jun",
        "AlWalimah(O)": "7.2%",
        "AlWalimah(S)": "6.2%",
        "Total AlWalimah": "13.4%",
        "AbuKass": "4.7%",
        "Tejan": "0.6%",
        "AlShalan": "4.1%",
        "AlShalan Punjabi": "1.2%",
        "Al Aila": "4.9%",
        "Punjabi Al Muhaidib": "10.5%",
        "BabAlHind": "0.5%",
        "White Swan": "5.2%",
        "Green Farms": "2.2%",
        "SunWhite": "7.3%",
        "Others": "31.9%"
    }
];
export const ManufacturerData: any = [
    {
        "City": "All Cities",
        "Wave": "W1 2019 Apr",
        "Total Mayar": "39%",
        "Total S A Babaker": "40%",
        "Total Alshalan": "23%",
        "Total Balsharaf": "3%",
        "Total SunRice": "17%",
        "Total Omar Al. Esayi": "19%"
    },
    {
        "City": "All Cities",
        "Wave": "W2 2019 Apr",
        "Total Mayar": "43%",
        "Total S A Babaker": "38%",
        "Total Alshalan": "21%",
        "Total Balsharaf": "2%",
        "Total SunRice": "17%",
        "Total Omar Al. Esayi": "22%"
    },
    {
        "City": "All Cities",
        "Wave": "W3 2019 Apr",
        "Total Mayar": "44%",
        "Total S A Babaker": "35%",
        "Total Alshalan": "20%",
        "Total Balsharaf": "3%",
        "Total SunRice": "20%",
        "Total Omar Al. Esayi": "25%"
    },
    {
        "City": "All Cities",
        "Wave": "W4 2019 Apr",
        "Total Mayar": "46%",
        "Total S A Babaker": "33%",
        "Total Alshalan": "17%",
        "Total Balsharaf": "6%",
        "Total SunRice": "24%",
        "Total Omar Al. Esayi": "33%"
    },
    {
        "City": "All Cities",
        "Wave": "W1 2019 May",
        "Total Mayar": "43%",
        "Total S A Babaker": "36%",
        "Total Alshalan": "24%",
        "Total Balsharaf": "4%",
        "Total SunRice": "23%",
        "Total Omar Al. Esayi": "34%"
    },
    {
        "City": "All Cities",
        "Wave": "W2 2019 May",
        "Total Mayar": "43%",
        "Total S A Babaker": "27%",
        "Total Alshalan": "30%",
        "Total Balsharaf": "4%",
        "Total SunRice": "21%",
        "Total Omar Al. Esayi": "32%"
    },
    {
        "City": "All Cities",
        "Wave": "W3 2019 May",
        "Total Mayar": "70%",
        "Total S A Babaker": "20%",
        "Total Alshalan": "20%",
        "Total Balsharaf": "5%",
        "Total SunRice": "15%",
        "Total Omar Al. Esayi": "20%"
    },
    {
        "City": "All Cities",
        "Wave": "W4 2019 May",
        "Total Mayar": "44%",
        "Total S A Babaker": "41%",
        "Total Alshalan": "37%",
        "Total Balsharaf": "2%",
        "Total SunRice": "15%",
        "Total Omar Al. Esayi": "20%"
    },
    {
        "City": "RIYADH",
        "Wave": "W1 2019 Apr",
        "Total Mayar": "36%",
        "Total S A Babaker": "52%",
        "Total Alshalan": "25%",
        "Total Balsharaf": "2%",
        "Total SunRice": "13%",
        "Total Omar Al. Esayi": "14%"
    },
    {
        "City": "RIYADH",
        "Wave": "W2 2019 Apr",
        "Total Mayar": "37%",
        "Total S A Babaker": "49%",
        "Total Alshalan": "17%",
        "Total Balsharaf": "2%",
        "Total SunRice": "15%",
        "Total Omar Al. Esayi": "19%"
    },
    {
        "City": "RIYADH",
        "Wave": "W3 2019 Apr",
        "Total Mayar": "41%",
        "Total S A Babaker": "42%",
        "Total Alshalan": "17%",
        "Total Balsharaf": "2%",
        "Total SunRice": "17%",
        "Total Omar Al. Esayi": "15%"
    },
    {
        "City": "RIYADH",
        "Wave": "W4 2019 Apr",
        "Total Mayar": "47%",
        "Total S A Babaker": "33%",
        "Total Alshalan": "10%",
        "Total Balsharaf": "5%",
        "Total SunRice": "18%",
        "Total Omar Al. Esayi": "22%"
    },
    {
        "City": "RIYADH",
        "Wave": "W1 2019 May",
        "Total Mayar": "43%",
        "Total S A Babaker": "46%",
        "Total Alshalan": "30%",
        "Total Balsharaf": "5%",
        "Total SunRice": "19%",
        "Total Omar Al. Esayi": "27%"
    },
    {
        "City": "RIYADH",
        "Wave": "W2 2019 May",
        "Total Mayar": "45%",
        "Total S A Babaker": "31%",
        "Total Alshalan": "47%",
        "Total Balsharaf": "2%",
        "Total SunRice": "18%",
        "Total Omar Al. Esayi": "27%"
    },
    {
        "City": "RIYADH",
        "Wave": "W3 2019 May",
        "Total Mayar": "50%",
        "Total S A Babaker": "25%",
        "Total Alshalan": "50%",
        "Total Balsharaf": "0%",
        "Total SunRice": "25%",
        "Total Omar Al. Esayi": "25%"
    },
    {
        "City": "RIYADH",
        "Wave": "W4 2019 May",
        "Total Mayar": "37%",
        "Total S A Babaker": "54%",
        "Total Alshalan": "37%",
        "Total Balsharaf": "2%",
        "Total SunRice": "10%",
        "Total Omar Al. Esayi": "8%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W1 2019 Apr",
        "Total Mayar": "39%",
        "Total S A Babaker": "31%",
        "Total Alshalan": "23%",
        "Total Balsharaf": "4%",
        "Total SunRice": "18%",
        "Total Omar Al. Esayi": "23%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W2 2019 Apr",
        "Total Mayar": "40%",
        "Total S A Babaker": "31%",
        "Total Alshalan": "26%",
        "Total Balsharaf": "4%",
        "Total SunRice": "18%",
        "Total Omar Al. Esayi": "25%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W3 2019 Apr",
        "Total Mayar": "41%",
        "Total S A Babaker": "30%",
        "Total Alshalan": "28%",
        "Total Balsharaf": "4%",
        "Total SunRice": "24%",
        "Total Omar Al. Esayi": "32%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W4 2019 Apr",
        "Total Mayar": "39%",
        "Total S A Babaker": "34%",
        "Total Alshalan": "24%",
        "Total Balsharaf": "8%",
        "Total SunRice": "28%",
        "Total Omar Al. Esayi": "41%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W1 2019 May",
        "Total Mayar": "39%",
        "Total S A Babaker": "22%",
        "Total Alshalan": "15%",
        "Total Balsharaf": "4%",
        "Total SunRice": "25%",
        "Total Omar Al. Esayi": "34%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W2 2019 May",
        "Total Mayar": "37%",
        "Total S A Babaker": "19%",
        "Total Alshalan": "21%",
        "Total Balsharaf": "5%",
        "Total SunRice": "22%",
        "Total Omar Al. Esayi": "30%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W3 2019 May",
        "Total Mayar": "63%",
        "Total S A Babaker": "25%",
        "Total Alshalan": "25%",
        "Total Balsharaf": "0%",
        "Total SunRice": "13%",
        "Total Omar Al. Esayi": "25%"
    },
    {
        "City": "JEDDAH",
        "Wave": "W4 2019 May",
        "Total Mayar": "44%",
        "Total S A Babaker": "29%",
        "Total Alshalan": "36%",
        "Total Balsharaf": "3%",
        "Total SunRice": "18%",
        "Total Omar Al. Esayi": "28%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W1 2019 Apr",
        "Total Mayar": "55%",
        "Total S A Babaker": "57%",
        "Total Alshalan": "17%",
        "Total Balsharaf": "3%",
        "Total SunRice": "25%",
        "Total Omar Al. Esayi": "10%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W2 2019 Apr",
        "Total Mayar": "55%",
        "Total S A Babaker": "47%",
        "Total Alshalan": "26%",
        "Total Balsharaf": "1%",
        "Total SunRice": "24%",
        "Total Omar Al. Esayi": "23%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W3 2019 Apr",
        "Total Mayar": "48%",
        "Total S A Babaker": "56%",
        "Total Alshalan": "28%",
        "Total Balsharaf": "2%",
        "Total SunRice": "30%",
        "Total Omar Al. Esayi": "33%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W4 2019 Apr",
        "Total Mayar": "60%",
        "Total S A Babaker": "49%",
        "Total Alshalan": "21%",
        "Total Balsharaf": "8%",
        "Total SunRice": "37%",
        "Total Omar Al. Esayi": "40%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W1 2019 May",
        "Total Mayar": "57%",
        "Total S A Babaker": "45%",
        "Total Alshalan": "17%",
        "Total Balsharaf": "7%",
        "Total SunRice": "33%",
        "Total Omar Al. Esayi": "47%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W2 2019 May",
        "Total Mayar": "49%",
        "Total S A Babaker": "32%",
        "Total Alshalan": "33%",
        "Total Balsharaf": "4%",
        "Total SunRice": "26%",
        "Total Omar Al. Esayi": "39%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W3 2019 May",
        "Total Mayar": "69%",
        "Total S A Babaker": "19%",
        "Total Alshalan": "19%",
        "Total Balsharaf": "6%",
        "Total SunRice": "19%",
        "Total Omar Al. Esayi": "19%"
    },
    {
        "City": "DAMMAM",
        "Wave": "W4 2019 May",
        "Total Mayar": "54%",
        "Total S A Babaker": "49%",
        "Total Alshalan": "38%",
        "Total Balsharaf": "2%",
        "Total SunRice": "19%",
        "Total Omar Al. Esayi": "24%"
    }
];
export const Total: any = [
    {
        "DataType": "Number",
        "Wave": "W1 2019 Apr",
        "Total Rice": "160",
        "Total Sella": "105",
        "Total Basmati": "91",
        "Total Calrose": "56"
    },
    {
        "DataType": "Number",
        "Wave": "W2 2019 Apr",
        "Total Rice": "165",
        "Total Sella": "108",
        "Total Basmati": "85",
        "Total Calrose": "55"
    },
    {
        "DataType": "Number",
        "Wave": "W3 2019 Apr",
        "Total Rice": "171",
        "Total Sella": "113",
        "Total Basmati": "96",
        "Total Calrose": "62"
    },
    {
        "DataType": "Number",
        "Wave": "W4 2019 Apr",
        "Total Rice": "178",
        "Total Sella": "123",
        "Total Basmati": "97",
        "Total Calrose": "77"
    },
    {
        "DataType": "Number",
        "Wave": "W1 2019 May",
        "Total Rice": "167",
        "Total Sella": "114",
        "Total Basmati": "103",
        "Total Calrose": "71"
    },
    {
        "DataType": "Number",
        "Wave": "W2 2019 May",
        "Total Rice": "158",
        "Total Sella": "109",
        "Total Basmati": "92",
        "Total Calrose": "62"
    },
    {
        "DataType": "Number",
        "Wave": "W3 2019 May",
        "Total Rice": "20",
        "Total Sella": "12",
        "Total Basmati": "14",
        "Total Calrose": "9"
    },
    {
        "DataType": "Number",
        "Wave": "W4 2019 May",
        "Total Rice": "172",
        "Total Sella": "130",
        "Total Basmati": "93",
        "Total Calrose": "49"
    },
    {
        "DataType": "Percentage",
        "Wave": "W1 2019 Apr",
        "Total Sella": "65.6%",
        "Total Basmati": "56.9%",
        "Total Calrose": "35.0%"
    },
    {
        "DataType": "Percentage",
        "Wave": "W2 2019 Apr",
        "Total Sella": "65.5%",
        "Total Basmati": "51.5%",
        "Total Calrose": "33.3%"
    },
    {
        "DataType": "Percentage",
        "Wave": "W3 2019 Apr",
        "Total Sella": "66.1%",
        "Total Basmati": "56.1%",
        "Total Calrose": "36.3%"
    },
    {
        "DataType": "Percentage",
        "Wave": "W4 2019 Apr",
        "Total Sella": "69.1%",
        "Total Basmati": "54.5%",
        "Total Calrose": "43.3%"
    },
    {
        "DataType": "Percentage",
        "Wave": "W1 2019 May",
        "Total Sella": "68.3%",
        "Total Basmati": "61.7%",
        "Total Calrose": "42.5%"
    },
    {
        "DataType": "Percentage",
        "Wave": "W2 2019 May",
        "Total Sella": "69.0%",
        "Total Basmati": "58.2%",
        "Total Calrose": "39.2%"
    },
    {
        "DataType": "Percentage",
        "Wave": "W3 2019 May",
        "Total Sella": "60.0%",
        "Total Basmati": "70.0%",
        "Total Calrose": "45.0%"
    },
    {
        "DataType": "Percentage",
        "Wave": "W4 2019 May",
        "Total Sella": "75.6%",
        "Total Basmati": "54.1%",
        "Total Calrose": "28.5%"
    }
];
export const Filters: any = [
    {
        "Id": "Total Mayar",
        "Title": "Total Mayar",
        "Type": "Brand",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "Total S A Babaker",
        "Title": "Total S A Babaker",
        "Type": "Brand",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "Total Alshalan",
        "Title": "Total Alshalan",
        "Type": "Brand",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "Total Balsharaf",
        "Title": "Total Balsharaf",
        "Type": "Brand",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "Total SunRice",
        "Title": "Total SunRice",
        "Type": "Brand",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "Total Omar Al. Esayi",
        "Title": "Total Omar Al. Esayi",
        "Type": "Brand",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "AlWalimah(O)",
        "Title": "AlWalimah(O)",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "AlWalimah(S)",
        "Title": "AlWalimah(S)",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "Total AlWalimah",
        "Title": "Total AlWalimah",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "AbuKass",
        "Title": "AbuKass",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "Tejan",
        "Title": "Tejan",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "AlShalan",
        "Title": "AlShalan",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "AlShalan Punjabi",
        "Title": "AlShalan Punjabi",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "Al Aila",
        "Title": "Al Aila",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "Punjabi Al Muhaidib",
        "Title": "Punjabi Al Muhaidib",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "BabAlHind",
        "Title": "BabAlHind",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "White Swan",
        "Title": "White Swan",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "Green Farms",
        "Title": "Green Farms",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "SunWhite",
        "Title": "SunWhite",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "Others",
        "Title": "Others",
        "Type": "Brand",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "W1 2019 Apr",
        "Title": "W1 2019 Apr",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "W2 2019 Apr",
        "Title": "W2 2019 Apr",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "W3 2019 Apr",
        "Title": "W3 2019 Apr",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "W4 2019 Apr",
        "Title": "W4 2019 Apr",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "W1 2019 May",
        "Title": "W1 2019 May",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "W2 2019 May",
        "Title": "W2 2019 May",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "W3 2019 May",
        "Title": "W3 2019 May",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "W4 2019 May",
        "Title": "W4 2019 May",
        "Type": "Wave",
        "Page": "Display",
        "Selected": "TRUE"
    },
    {
        "Id": "W1 2019 May",
        "Title": "W1 2019 May",
        "Type": "Wave",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "W2 2019 May",
        "Title": "W2 2019 May",
        "Type": "Wave",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "W3 2019 May",
        "Title": "W3 2019 May",
        "Type": "Wave",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "W4 2019 May",
        "Title": "W4 2019 May",
        "Type": "Wave",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "W1 2019 Jun",
        "Title": "W1 2019 Jun",
        "Type": "Wave",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "W2 2019 Jun",
        "Title": "W2 2019 Jun",
        "Type": "Wave",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "All Cities",
        "Title": "All Cities",
        "Type": "City",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "RIYADH",
        "Title": "RIYADH",
        "Type": "City",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "DAMMAM",
        "Title": "DAMMAM",
        "Type": "City",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "JEDDAH",
        "Title": "JEDDAH",
        "Type": "City",
        "Page": "Shelf",
        "Selected": "FALSE"
    },
    {
        "Id": "All Cities",
        "Title": "All Cities",
        "Type": "City",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "RIYADH",
        "Title": "RIYADH",
        "Type": "City",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "DAMMAM",
        "Title": "DAMMAM",
        "Type": "City",
        "Page": "Display",
        "Selected": "FALSE"
    },
    {
        "Id": "JEDDAH",
        "Title": "JEDDAH",
        "Type": "City",
        "Page": "Display",
        "Selected": "FALSE"
    }
];
