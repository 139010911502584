export const MENU: any = [
    {
        title: 'Share of Facing',
        icon: {
            class: 'fa fa-check',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/facing'
    },
    {
        title: 'Price',
        icon: {
            class: 'fa fa-check',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/price'
    },
    {
        title: 'Display',
        icon: {
            class: 'fa fa-check',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/display'
    },
    {
        title: 'Pictures',
        icon: {
            class: 'fa fa-check',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/pictures'
    },
    {
        title: 'Scorecard',
        icon: {
            class: 'fa fa-check',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/scorecard'
    },
    {
        title: 'Detailed Tables By Store',
        icon: {
            class: 'fa fa-check',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/detailed-tables-by-store'
    },
    //{
    //    title: 'Promotion',
    //    icon: {
    //        class: 'fa fa-check',
    //        bg: '#00568A',
    //        color: '#fff'
    //    },
    //    routing: '/promotion'
    //}
];
